import React, { FC } from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import './industry-privacy-selector.css'

const IndustryPrivacySelector = () => {
    return (
        <div className="industry-selector">
            <h2 className="text-3xl font-hairline font-heading text-grey-dark mb-3 industry-heading">
                Privacy Policies
            </h2>
            <AniLink fade className="block hover:text-grey-dark transition-color lg:text-base industry-picks" to="/privacy-entertainment-venues">Entertainment and Retail</AniLink>
            <AniLink fade className="block hover:text-grey-dark transition-color lg:text-base industry-picks" to="/privacy-online-age-verification">Online Age Verification</AniLink>
            <AniLink fade className="block hover:text-grey-dark transition-color lg:text-base industry-picks" to="/privacy-guest-register">Guest Registers</AniLink>
        </div>
        )
    };

export default IndustryPrivacySelector