import React from 'react';
 import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import './accordion-privacy-faq.css';

export default function AccordingPrivacyFAQ(props) {
    const createMarkup = (html: string) => {
        return { __html: html }
    }

    return (
        <Accordion allowZeroExpanded allowMultipleExpanded className="">
            {props.questions.map((question) => (
                <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {question.title}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div key={question.question}>
                        <div dangerouslySetInnerHTML={createMarkup(question.response)}></div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            ))}
        </Accordion>
    );
}