import React from 'react';
import { gaButtonEvent } from '../../../services/visitor-info';
import './privacy-buttons.css';

export const PrivacyButtons = () => (
  <div className="privacy-button-container">
    <a
      href={'https://dispute.patronscan.com/investigation-request/'}
      onClick={() => {
        gaButtonEvent('Iwanttodisputeaflag', 'FlagDisputePB');
      }}
      className="privacy-button"
    >
      {'I want to dispute a flag'}
    </a>
    <a
      href={'https://dispute.patronscan.com/disclosure-request/'}
      onClick={() => {
        gaButtonEvent('Iwanttocheckmyinfo', 'InfoCheckPB');
      }}
      className="privacy-button"
    >
      {'I want to check my info'}
    </a>
  </div>
);

export default PrivacyButtons;
