import React, { FC } from 'react';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import './privacy-industry-hero.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

interface PrivacyHeroProps {
  heroImage?: any;
  heading?: string;
  subHeading?: string;
  headingText?: string;
}

const PrivacyIndustryHero: FC<PrivacyHeroProps> = (props) => {
  return (
    <BackgroundImage className="w-full background-image" fluid={props.heroImage}>
      <section id="privacy-industry-header" className="pt-12 w-full">
        <div className="privacy-industry-container">
          <div className="privacy-industrytextblock">
            <h2 className="text-white font-heading text-4xl mb-4 md:text-5xl font-bold leading-none">
              {props.heading}
            </h2>
            <h4 className="text-white privacy-industrysubheading">{props.subHeading}</h4>
            <p className="text-white text-xl font-hairline max-w-2xl leading-relaxed privacy-headingText">
              {props.headingText}
            </p>
          </div>
        </div>
      </section>
    </BackgroundImage>
  );
};

export default PrivacyIndustryHero;
